.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #250b59;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #218c74;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #3db551;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.rounded-br {
  border-bottom-right-radius: 0.5rem;
}

.rounded-t {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.rounded-br-lg {
  border-bottom-right-radius: 1.25rem;
}

.rounded-bl-sm {
  border-bottom-left-radius: 0.5rem;
}

.rounded-t-lg {
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}

.rounded-br-lg2 {
  border-top-left-radius: 1.25rem;
}

.rounded-bl-sm2 {
  border-top-right-radius: 0.5rem;
}

.rounded-t-lg2 {
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

.rounded-br-lg3 {
  border-bottom-right-radius: 1.1rem;
}

.rounded-bl-sm3 {
  border-bottom-left-radius: 0.8rem;
}

.rounded-t-lg3 {
  border-top-left-radius: 1.1rem;
  border-top-right-radius: 0.8rem;
}

.loader {
  display: inline-block;
  position: relative;
  height: 32px;
  width: 120px;
  border-bottom: 5px solid #000;
  box-sizing: border-box;
  animation: balancing 2s linear infinite alternate;
  transform-origin: 50% 100%;
}
.loader:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #ff3d00;
  animation: ballbns 2s linear infinite alternate;
}
.loader:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  height: 20px;
  width: 20px;
  transform: translate(-50%, 100%);
  border-radius: 50%;
  border: 6px solid #000;
}
@keyframes ballbns {
  0% {
    left: 0;
    transform: translateX(0%);
  }
  100% {
    left: 100%;
    transform: translateX(-100%);
  }
}
@keyframes balancing {
  0% {
    transform: rotate(-15deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(15deg);
  }
}

.fs-icon {
  font-size: 1.38rem !important;
}

.btn-purple {
  background-color: #2c4f40;
  border: #2c4f40;
  color: #ffffff;
}

.btn-purple:hover {
  background-color: #2c4f40;
  border: #2c4f40;
  color: #ffffff;
}

.btn-pink {
  background: rgb(231, 67, 156);
  border: rgb(231, 67, 156);
  color: #ffffff;
}

.border-purple {
  background: #fff;
  border-color: #2c4f40 !important;
}

.btn-purple2 {
  background: #250b59;
  border: #250b59;
  color: #ffffff;
}

.btn-purple3 {
  background: #c6c5ff;
  border: #c6c5ff;
  color: #ffffff;
}

.bg-purple3 {
  background: #250b59;
  border: #250b59;
  color: #ffffff;
}

.bg-header {
  background: #f7b731;
}

.bg-trans {
  background: #ffffff28;
}

.bg-header2 {
  background: #8a02d2;
}

.bg-yellow {
  background: #feca57;
  border: #feca57;
  color: #ffffff;
}

.bg-blue {
  background: #48dbfb;
  border: #48dbfb;
  color: #ffffff;
}

.bg-purple {
  background: #40196d;
  border: #40196d;
  color: #ffffff;
}

.bg-navheader {
  background: #3d2467;
  border: #3d2467;
  color: #ffffff;
}

.bg-danger2 {
  background: #ff6b81;
  border: #ff6b81;
  color: #fff;
}

.bg-purple1 {
  background: #3db551;
  border: #3db551;
  color: #ffffff;
}

.bg-purple2 {
  background: #c6c5ff;
  border: #c6c5ff;
  color: #ffffff;
}

.bg-purple0 {
  background: #a29bfe;
  border: #a29bfe;
  color: #ffffff;
}

.bg-pink {
  background: #fd79a8;
  border: #fd79a8;
  color: #ffffff;
}

.text-pink {
  color: #fd79a8;
}

.bg-result {
  background: #40407a;
  border: #40407a;
  color: #ffffff;
}

.bg-green {
  background: #00b894;
  border: #00b894;
  color: #00b894;
}

.text-success2 {
  color: #3db551;
}

.text-orange {
  color: #ff793f;
}

.text-purple {
  color: #2c4f40;
}

.text-purple1 {
  color: #6c5ce7;
}

.text-purple2 {
  color: #250b59;
}

.text-purple3 {
  color: #40196d;
}

.text-header {
  color: #7b7fda;
}

.bg-back {
  background-color: #f9f9f9 !important;
  background: #f9f9f9 !important;
  border: #f9f9f9 !important;
}

.bg-warning-50 {
  background: #fbe0c8;
  border: #fbe0c8;
}

.bg-class {
  background: #a163f7;
  border: #a163f7;
  color: #ffffff;
}

.bg-class_ {
  background: #8ad879;
  border: #8ad879;
  color: #ffffff;
}

.bg-classwork {
  background: #dbd5e5;
  border: #dbd5e5;
  color: #ffffff;
}

.bg-home {
  background: #fb806f;
  border: #fb806f;
  color: #ffffff;
}

.text-trans {
  color: transparent;
}

::-webkit-scrollbar {
  min-width: 8px;
  max-width: 8px;
  padding-left: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.fs-10 {
  font-size: 30px;
}

.fs-t {
  font-size: 25px;
}

.fs-t2 {
  font-size: 35px;
}

.font-body {
  font-family: Karla, sans-serif;
  color: rgb(231, 67, 156);
}

.text-justify {
  text-align: justify;
  text-justify: inter-word;
}

.bg-terms {
  background: rgb(209, 245, 234);
  background: radial-gradient(
    circle,
    rgba(209, 245, 234, 1) 28%,
    rgba(255, 255, 255, 1) 99%
  );
}

.rounded-2 {
  border-radius: 100px;
}

#name-input:valid {
  text-transform: uppercase;
}

#name-input1:valid {
  text-transform: uppercase;
}

#name-input2:valid {
  text-transform: lowercase;
}

.rotate {
  animation: rotation infinite 3s linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) {
  .md\:block {
    display: block;
  }
}

.w-full {
  width: 100%;
}

.top-0 {
  top: 0;
}

.h-1 {
  height: 0.25rem;
}

.absolute {
  position: absolute;
}

.float {
  position: fixed;
  bottom: 15px;
  right: 22px;
  text-align: center;
}

.float4 {
  position: fixed;
  bottom: 40px;
  right: 50px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .float4 {
    position: fixed;
    bottom: 90px;
    right: 22px;
    text-align: center;
  }
}

.float2 {
  position: fixed;
  top: 30px;
  right: 10px;
  text-align: center;
}

.float3 {
  position: fixed;
  top: 10px;
  right: 10px;
  text-align: center;
}

.bg-wgreen {
  background: rgb(244, 205, 255);
  background: linear-gradient(
    90deg,
    rgba(244, 205, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
}

.bg-profile_ {
  background: rgb(146, 206, 170);
  background: linear-gradient(
    90deg,
    rgba(146, 206, 170, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(146, 206, 170, 1) 100%
  );
}

.navigation ul li {
  list-style: none;
  border-radius: 20px;
}

.navigation ul li:hover {
  background: #ffffff63;
}

.navigation ul li a {
  color: #fff;
}

.navigation ul li:hover a {
  color: #fff;
}

.bg-profile {
  background: #ff5252 !important;
  border: #ff5252;
}

.btn-purple_ {
  background: #2c2c54;
  border: #2c2c54;
  color: #ffffff;
}

.btn-purple_:hover {
  background: #fff;
  border: #2c2c54;
  color: #ff5252;
}

.btn-purple4 {
  background: #40196d !important;
  border: #40196d !important;
  color: #ffffff;
}

.btn-orange {
  background: #ff793f !important;
  border: #ff793f !important;
  color: #ffffff;
}

.Home {
  height: 100%;
  overflow: auto;
  background-image: url("../public/assets/images/hand.svg"),
    url("../public//assets/images/circle-bottom.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: 0 0, 0 100%;
  background-attachment: fixed;
}

.Home_ {
  height: 100%;
  overflow: auto;
  background-image: url("../public/assets/images/circle-right.svg"),
    url("../public//assets/images/circle-bottom.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: 100% 30%, 0 100%;
  background-attachment: fixed;
}

.cbt {
  height: 10%;
  overflow: auto;
  background-image: url("../public/assets/images/cbt.svg"),
    url("../public//assets/images/circle-bottom.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: right top, 0 100%;
  background-size: 60%;
  background-attachment: fixed;
}

.cbt2 {
  height: 10%;
  overflow: auto;
  background-image: url("../public/assets/images/cbt2.svg"),
    url("../public//assets/images/circle-bottom.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: right top, 0 100%;
  background-size: 50%;
  background-attachment: fixed;
}

.Demo {
  height: 10%;
  overflow: auto;
  background-image: url("../public/assets/images/demo.svg"),
    url("../public//assets/images/circle-bottom.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: right top, 0 100%;
  background-size: 46%;
  background-attachment: fixed;
}

.Test {
  height: 30%;
  overflow: auto;
  background-image: url("../public/assets/images/cbt4.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: right top, 0 100%;
  background-size: 45%;
  background-attachment: fixed;
}

.Stud {
  height: 10%;
  overflow: auto;
  background-image: url("../public/assets/images/students.svg"),
    url("../public//assets/images/circle-bottom.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: right 15%, 0 100%;
  background-size: 55%;
  background-attachment: fixed;
}

.Exercise {
  height: 10%;
  overflow: auto;
  background-image: url("../public/assets/images/exercise.svg"),
    url("../public//assets/images/circle-bottom.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: right 15%, 0 100%;
  background-size: 55%;
  background-attachment: fixed;
}

.curve {
  background-image: url("../public/assets/images/curve2.svg"),
    url("../public/assets/images/curve1.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: right top, left bottom;
  background-size: 18%, 24%;
  background-position: fixed;
}

.curve_ {
  background-image: url("../public/assets/images/curve2.svg"),
    url("../public/assets/images/curve1.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: left top, right bottom;
  background-size: 24%;
  background-position: fixed;
}

.curve1 {
  background-image: url("../public/assets/images/curve1.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
}

.curve2 {
  background-image: url("../public/assets/images/curve2.svg");
  background-repeat: no-repeat;
  background-position: center top;
}

.curve3 {
  background-image: url("../public/assets/images/curve1.svg");
  background-repeat: no-repeat;
  background-position: left bottom;
}

.curve4 {
  background-image: url("../public/assets/images/curve2.svg");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 24%;
  background-position: 85% 0%;
}

.curve6 {
  background-image: url("../public/assets/images/curve2.svg");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 24%;
}

.curve5 {
  background-image: url("../public/assets/images/cbt3.svg");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 35%;
}

.bg-orange_ {
  background-color: #ff793f !important;
  border: #ff793f !important;
}

.bg-orange {
  background-color: #ff793f !important;
  border: #ff793f !important;
  background-image: url("../public/assets/images/curve2.svg");
  background-repeat: no-repeat;
  background-position: right top;
}

.nav-link.active1 {
  background-color: transparent;
  border-bottom: 2px solid #474787;
  color: #474787;
}

.Lock {
  height: 100%;
  overflow: auto;
  background-image: url("../public/assets/images/warning.svg"),
    url("../public/assets/images/circle-right.svg"),
    url("../public//assets/images/circle-bottom.svg");
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: right top, 100%, 0 100%;
  background-size: 40%;
}

.bg-demo1 {
  background: #7b7fda;
  border: #7b7fda;
  color: #ffffff;
}

.bg-demo2 {
  background: #fca965;
  border: #fca965;
  color: #ffffff;
}

@media only screen and (max-width: 400px) {
  .ms-md-30 {
    margin-left: 10.5rem !important;
  }

  .Test {
    height: 10%;
    overflow: auto;
    background-image: url("../public/assets/images/cbt4.svg");
    background-repeat: no-repeat;
    background-position: right 16%;
    background-size: 80%;
    background-attachment: fixed;
  }

  .Home {
    height: 100%;
    overflow: auto;
    background-image: url("../public/assets/images/hand.svg")
      url("../public//assets/images/circle-bottom.svg");
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: 0 10%, 0 100%;
    background-attachment: fixed;
  }

  .cbt {
    height: 10%;
    overflow: auto;
    background-image: url("../public/assets/images/cbt.svg"),
      url("../public//assets/images/circle-bottom.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: right 15%, 0 100%;
    background-size: 90%;
    background-attachment: fixed;
  }

  .Stud {
    height: 10%;
    overflow: auto;
    background-image: url("../public/assets/images/students.svg"),
      url("../public//assets/images/circle-bottom.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: right 15%, 0 100%;
    background-size: 90%;
    background-attachment: fixed;
  }

  .Exercise {
    height: 10%;
    overflow: auto;
    background-image: url("../public/assets/images/exercise.svg"),
      url("../public//assets/images/circle-bottom.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: right 15%, 0 80%;
    background-size: 90%;
    background-attachment: fixed;
  }

  .cbt2 {
    height: 10%;
    overflow: auto;
    background-image: url("../public/assets/images/cbt2.svg"),
      url("../public//assets/images/circle-bottom.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: right top, 0 100%;
    background-size: 50%;
    background-attachment: fixed;
  }

  .Demo {
    height: 10%;
    overflow: auto;
    background-image: url("../public/assets/images/demo.svg"),
      url("../public//assets/images/circle-bottom.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: right 8%, 0 100%;
    background-size: 90%;
    background-attachment: fixed;
  }
}

@media only screen and (min-width: 600px) {
  .ms-md-30 {
    margin-left: 18.5rem !important;
  }
}

.end-3 {
  right: 30px !important;
}

.btn-trans {
  background-color: #f5f4f840;
  border-color: none;
  color: #000;
}

.btn-header {
  background-color: #ffffff28;
  border-color: none;
}

.via-fuchsia-400 {
  background: rgb(105, 255, 213);
  background: linear-gradient(
    105deg,
    rgba(105, 255, 213, 1) 22%,
    rgba(157, 255, 228, 1) 35%,
    rgba(120, 255, 217, 1) 45%,
    rgba(85, 239, 196, 1) 59%,
    rgba(85, 239, 196, 1) 100%
  );
}
.bg-work {
  background: rgb(117, 79, 254);
  background: linear-gradient(
    105deg,
    rgba(117, 79, 254, 1) 52%,
    rgba(89, 42, 255, 1) 76%
  );
}

.border-2 {
  border: 6px solid #fff !important;
}

.loader_2 {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: block;
  margin: 25px auto;
  position: relative;
  background: #a4b0be;
  box-shadow: -34px 0 #a4b0be, 34px 0 #a4b0be;
  box-sizing: border-box;
  animation: shadowPulse 2s linear infinite;
}

@keyframes shadowPulse {
  33% {
    background: #a4b0be;
    box-shadow: -34px 0 #40196d, 34px 0 #a4b0be;
  }
  66% {
    background: #40196d;
    box-shadow: -34px 0 #a4b0be, 34px 0 #a4b0be;
  }
  100% {
    background: #a4b0be;
    box-shadow: -34px 0 #a4b0be, 34px 0 #40196d;
  }
}

img {
  max-width: 100%;
}

p {
  font-size: 1.1rem !important;
  color: #000;
}

@media only screen and (max-width: 400px) {
  p {
    font-size: 1.25rem !important;
    color: #000;
  }
}

.preview {
  position: fixed;
  bottom: 28px;
  right: 28px;
  text-align: center;
}

.lds-heart {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: rotate(45deg);
  transform-origin: 40px 40px;
}
.lds-heart div {
  top: 32px;
  left: 32px;
  position: absolute;
  width: 32px;
  height: 32px;
  background: #d63031;
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  background: #d63031;
}
.lds-heart div:before {
  left: -24px;
  border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
  top: -24px;
  border-radius: 50% 50% 0 0;
}
@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}

.bg-start {
  background-color: #492b7c;
  background: #492b7c !important;
}

.bg-manage {
  background-color: #ffefbb;
}

.sh {
  box-shadow: 0 22px 50px rgba(0, 0, 0, 0.08);
}

@media (min-width: 768px) {
  .start-card {
    width: 560px;
    box-shadow: 0 22px 50px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    padding-top: 40px;
    background: #fff;
  }
}
